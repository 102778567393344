<template>
  <div
    id="product-list-v2__hypernym-wrap"
    class="product-list-v2__hypernym-wrap"
    :class="{ 'has-product': searchGoodsSum > 0 && info.sum > 0 && info.list.length}"
  >
    <transition name="slide-fade">
      <p
        v-if="info.sum && info.list.length"
        v-show="hintText"
        class="product-list-v2__hypernym-hint"
      >
        {{ hintText }}
      </p>
    </transition>
    <transition name="slide-fade">
      <ProductList
        v-if="info.list.length && !info.loading"
        :products-origin-data="info.list"
        :products-origin-sum="info.sum"
        :switch-btn="switchBtn"
      >
        <s-pagination
          :total-pages-lang="language.SHEIN_KEY_PC_18002"
          :current-page="info.page"
          :total="Number(info.sum)"
          :page-size="Number(info.limit)"
          align="right"
          :nextmove="false"
          @page-change="handlePageChange"
        />
      </ProductList>
    </transition>
    <transition name="slide-fade">
      <ProductListSkeleton
        v-if="info.loading"
        :style="{'margin-top': info.sum === 0 ? '' : searchGoodsSum === 0 ? '50px' : '90px'}"
        :num="15"
      />
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import { SPagination } from '@shein-aidc/sui-pagination'
import ProductListSkeleton from './ProductListSkeleton'

let scrollTop = 0
export default {
  name: 'HypernymProductWrap',
  emits: ['changePage'],
  components: {
    SPagination,
    ProductListSkeleton,
    ProductList: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "product_list" */ './ProductList.vue')
    }),
  },
  props: {
    searchGoodsSum: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object,
      default: () => ({
        page: 1,
        limit: 120,
        sum: 0,
        keywords: '',
        loading: false,
      }),
    },
  },
  data() {
    return {
      switchBtn: {
        productsOriginOpen: true,
        onlyOne: true,
      },
    }
  },
  computed: {
    ...mapState(['language']),
    hintText() {
      const { SHEIN_KEY_PC_27823 = '', SHEIN_KEY_PC_27824 = '' } = this.language
      const title =
        this.searchGoodsSum > 0 ? SHEIN_KEY_PC_27824 : SHEIN_KEY_PC_27823
      return (title || '').replace('{0}', `${this.info.keywords}`)
    },
  },
  mounted() {
    const mainEl = document.querySelector('#product-list-v2__hypernym-wrap')
    scrollTop = mainEl.getBoundingClientRect?.()?.top - 145 || 0
  },
  methods: {
    handlePageChange(next) {
      window.scrollTo(0, scrollTop)
      this.$emit('changePage', next)
    },
  }
}
</script>

  
<style lang="less">
.product-list-v2 {
  &__hypernym-wrap {
    margin-bottom: 20px;
    margin-top: -16px;
    &.has-product {
      margin-top: 16px;
      border-top: 2px var(--sui-color-gray-weak-2, #f6f6f6) solid;
      .product-list-v2__hypernym-hint {
        margin: 20px auto 12px;
      }
    }
  }
  &__hypernym-hint {
    text-align: center;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 17px;
    color: var(--sui-color-gray-dark-1, #222);
    word-wrap: break-word;
  }
}
</style>
  
