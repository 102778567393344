import schttp from 'public/src/services/schttp'

export default {
  methods: {
    async getBuyBoxPrices (goods) {
      const products = goods
        .filter(item => item.buyBoxCount?.count)
        .map(item => ({
          goods_id: item.goods_id,
          goods_sn: item.goods_sn,
          mall_code: item.mall_code,
          group_id: item.buyBoxCount.group_id,
        }))
    
      if (!products.length) return

      await this.$nextTick()
      window?.requestIdleCallback(async() => {
        const { list } = await schttp({
          method: 'POST',
          url: '/api/productList/buyBoxPrice/get',
          data: { products }
        })
        list?.forEach(item => {
          const curGoods = goods.find(obj => obj.goods_id === item.goods_id)
          if (!curGoods) return
          curGoods.buyBoxPrice = item.buyBoxPrice
        })
      }, {
        timeout: 1500,
      })
      
    },
  },
}
