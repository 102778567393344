import { createApp } from './app'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
const { app, store } = createApp()

if (window.gbRawData) {
  store.replaceState({ ...store.state, ...window.gbRawData })
}
app.use(infiniteScroll)
app.mount('#product-list-v2') // vue3会多加一层div，  不会替换div

