<template>
  <div class="search-empty">
    <!-- 1. 建议纠错 -->
    <template v-if="showSuggestCorrection">
      <div>
        <p class="search-empty__origin-word-main"> 
          {{ language.SHEIN_KEY_PC_23577 && language.SHEIN_KEY_PC_23577.replace('{0}',`${searchKeywords.suggest_words}`) }}
        </p>
        <p 
          class="search-empty__origin-word-hint"
          @click="(event) => $emit('searchSuggestOriginWord', event)"
          v-html="originSearchWordText"
        ></p>
      </div>
    </template>
    <!-- 2. 建议搜错词 旧-->
    <template
      v-else-if="!searchSuggestNew && searchKeywords.suggest_words && searchKeywords.suggest_total"
    >
      <p
        class="search-empty__suggest"
        @click="handleSuggestClick"
        v-html="
          language.SHEIN_KEY_PC_16661 &&
            language.SHEIN_KEY_PC_16661.replace(
              '{0}',
              `<b>${catInfo.keywords}</b>`
            ).replace('{1}', `<strong>${searchKeywords.suggest_words}</strong>`)
        "
      ></p>
    </template>
    <!-- 2. 建议搜错词 新 -->
    <template
      v-else-if="searchSuggestNew && searchKeywords.suggest_words && searchKeywords.suggest_total"
    >
      <p class="search-empty__suggest-new"> 
        {{ language.SHEIN_KEY_PC_23576 && language.SHEIN_KEY_PC_23576.replace('{0}',`${catInfo.keywords}`) }}
      </p>
      <p class="search-empty__suggest-new-strong"> 
        {{ language.SHEIN_KEY_PC_23577 && language.SHEIN_KEY_PC_23577.replace('{0}',`${searchKeywords.suggest_words}`) }}
      </p>
    </template>
    <!-- 3. 空结果页面 old-->
    <template v-else-if="!searchSuggestNew && sum == 0">
      <div class="search-empty__help fsp-element">
        <sui_img_nodata_default_75px
          v-if="IS_RW"
          size="75px"
          color="#fc4070"
        />
        <i
          v-else
          class="search-empty__help-icon svgicon svgicon-empty"
        ></i>
        <p
          v-if="!IS_RW"
          class="search-empty__help-desc"
          v-html="
            language.SHEIN_KEY_PC_16375 &&
              language.SHEIN_KEY_PC_16375.replace(
                '{0}',
                `<b>${catInfo.keywords}</b>`
              )
          "
        ></p>
        <p
          v-else
          class="search-empty__help-desc"
          v-html="
            language.SHEIN_KEY_PC_24699 &&
              language.SHEIN_KEY_PC_24699.replace(
                '{0}',
                `<b>${catInfo.keywords}</b>`
              )
          "
        ></p>
        <div class="search-empty__help-list">
          {{ !IS_RW ? language.SHEIN_KEY_PC_16376 : '' }}
          <ul v-if="!IS_RW">
            <li><span></span>{{ language.SHEIN_KEY_PC_16377 }}</li>
            <li><span></span>{{ language.SHEIN_KEY_PC_16378 }}</li>
          </ul>

          <FeedBack :style-type="'empty'" />
        </div>
      </div>
      <!-- 相关搜索/搜索热词 -->
      <div class="search-empty__related-search">
        <RelatedSearch :style-type="'empty'" />
      </div>
    </template>
    <!-- 3. 空结果页面 new-->
    <template v-else-if="searchSuggestNew && sum == 0">
      <sui_img_nodata_default_75px
        v-if="IS_RW"
        size="75px"
        color="#fc4070"
      />
      <i 
        v-else
        class="search-empty__help-icon svgicon svgicon-empty"
        style="margin-top: 120px;"
      ></i>
      <!-- 相关搜索/搜索热词 -->
      <p class="search-empty__no-result-title fsp-element">
        {{ language.SHEIN_KEY_PC_23576 && language.SHEIN_KEY_PC_23576.replace('{0}',`${catInfo.keywords.slice(0, 50)}`) }}
      </p>
      <RelatedSearch 
        :style-type="'empty'" 
        :search-suggest-new="searchSuggestNew"
      />
      <div class="search-empty__related-search-fb">
        <FeedBack :style-type="'empty'" />
      </div>
    </template>
  </div>
</template>

<script>
import 'public/src/icon/empty.svg'
import { mapState, mapGetters } from 'vuex'
import FeedBack from './FeedBack'
import RelatedSearch from './RelatedSearch'
import searchWordsGlobalAnalysis from 'public/src/pages/common/biz_helper/gb_sw_analysis'
import { SEARCH_TYPE_ENUM, getSearchSourceBySearchType } from 'public/src/pages/common/search_words/const'
import { sui_img_nodata_default_75px } from '@shein-aidc/icon-vue3'

export default {
  name: 'SearchEmpty',
  emits: ['searchSuggestOriginWord'],
  components: {
    FeedBack,
    RelatedSearch,
    sui_img_nodata_default_75px,
  },
  props: {
    isSuggestCorrection: {
      type: Boolean,
      default: false
    },
    originSearchWordText: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['language', 'IS_RW']),
    ...mapGetters(['catInfo', 'searchKeywords', 'sum', 'listAbtResult']),
    searchSuggestNew() {
      return this.listAbtResult?.NoResultPage?.param?.NoResultPage === 'new'
    },
    showSuggestCorrection() {
      // 建议纠错
      return this.isSuggestCorrection && this.searchKeywords.suggest_words
    }
  },
  methods: {
    /**
     * suggest words 的点击
     */
    handleSuggestClick(e, is_force) {
      const target = e.target
      if (target.tagName !== 'STRONG') return
      const keywords = target.innerHTML
      searchWordsGlobalAnalysis.set({
        result_type: 2,
        result_word: keywords,
      })
      // 跳转建议搜索词
      // location.href = '/pdsearch/' + keywords + '/?is_click=1'
      const searchSource = getSearchSourceBySearchType(SEARCH_TYPE_ENUM.EDIT_SEARCH)
      const url = `/pdsearch/${keywords}/?ici=${searchWordsGlobalAnalysis.getPageFrom()}&search_source=${searchSource}`
      location.href = url + (is_force ? '&is_force=1' : '')
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .search-empty {
    text-align: center;
    cursor: default;
    &__help {
      padding-top: 40px;
    }
    &__suggest {
      font-size: 14px;
      margin-bottom: 25px;
      strong {
        cursor: pointer;
        color: @sui_color_link;
        &:hover {
          color: #c45500;
        }
      }
    }
    &__suggest-new {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: @sui_color_gray_dark1;
      margin-bottom: 6px;
    }
    &__suggest-new-strong {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: @sui_color_gray_dark1;
      margin-bottom: 12px;
    }
    &__help-icon {
      display: inline-block;
      margin-bottom: 18px;
      width: 75px;
      height: 75px;
      color: @sui_color_main;
    }

    &__help-desc {
      line-height: 25px;
      margin-bottom: 18px;
      font-size: 12px;
      color: #999;
      /* rw:begin */
      width: 375px;
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      b {
        color: black;
      }
    }

    &__help-list {
      display: inline-block;
      margin: 0 auto;
      // width: fit-content;
      line-height: 25px;
      font-size: 12px;
      text-align: left /*rtl:ignore*/;
      color: #999;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-top: -2px;
        margin-right: 5px /*rtl:ignore*/;
        background-color: #999;
        border-radius: 50%;
        vertical-align: middle;
      }
    }
    &__no-result-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: @sui_color_gray_dark1;
      text-align: center;
      padding: 2px 0 10px;
      word-break: break-all;
    }
    &__related-search-fb {
      .feed-back_empty {
        max-width: 100%;
        margin-top: 7px;
      }
      .feed-back__link {
        color: #2D68A8;
      }
    }
    &__origin-word-main {
      color: #000;
      font-size: 16.5px;
      font-weight: 700;
      line-height: 19px;
      margin-bottom: 6px;
    }
    &__origin-word-hint {
      color: #666;
      font-size: 11px;
      font-weight: 400;
      line-height: 10px;
      margin-bottom: 16px;
      .link {
        cursor: pointer;
        color: #2D68A8;
        text-decoration: underline;
      }
    }
  }
}
</style>
